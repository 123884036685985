<template>
    <div class="account-pages my-5 pt-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card overflow-hidden admin">
                        <div class="bg-soft bg-primary">
                            <div
                                class="d-flex justify-content-between align-items-center p-4 pb-3">
                                <div class="text-dark">
                                    <h5 class="text-dark fw-bolder m-0">
                                        {{ $t('admin') }}
                                    </h5>
                                </div>

                                <b-dropdown
                                    class="noti-icon flags"
                                    right
                                    toggle-class="p-0"
                                    variant="black">
                                    <template v-slot:button-content>
                                        <div>
                                            <img
                                                width="26"
                                                :src="current_locale_flag"
                                                alt="" />
                                        </div>
                                    </template>
                                    <b-dropdown-item
                                        v-for="(locale, i) in languages"
                                        :key="`locale-${i}`"
                                        @click="changeLocale(locale.language)"
                                        :active="
                                            locale.language == $i18n.locale
                                        ">
                                        <img
                                            width="18"
                                            :src="locale.flag"
                                            alt=""
                                            class="me-3" />
                                        {{ $t(locale.title) }}</b-dropdown-item
                                    >
                                </b-dropdown>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="alert alert-danger" v-if="auth_limit">
                                <h5 class="alert-heading">
                                    {{ $t('too many attempts') }}!
                                </h5>
                                <p class="m-0">
                                    {{
                                        $t(
                                            `you have reached the maximum login attempts. you will be able to try again in`
                                        )
                                    }}
                                    <span class="fw-bold text-danger">{{
                                        timer || '--:--'
                                    }}</span
                                    >.
                                </p>
                            </div>
                            <div
                                class="alert alert-warning"
                                v-if="error_message">
                                <p class="m-0">
                                    {{ $t(error_message) }}
                                </p>
                            </div>

                            <b-form class="p-2" @submit.prevent="tryToLogIn">
                                <b-form-group
                                    class="mb-3"
                                    id="input-group-1"
                                    :label="$t(`username`)"
                                    label-for="input-1">
                                    <input
                                        autocomplete="off"
                                        ref="username"
                                        @focus="saveFocus('username')"
                                        @keyup="checkUsernamePassword"
                                        class="form-control"
                                        v-model="username"
                                        type="text"
                                        :disabled="
                                            auth_limit || loading || collapsing
                                        "
                                        :placeholder="$t('enter username')"
                                        :class="{
                                            'is-invalid':
                                                submitted && v$.username.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.username
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">{{
                                            $t(item.$message)
                                        }}</span>
                                    </div>
                                </b-form-group>
                                <b-form-group
                                    class="mb-3"
                                    id="input-group-2"
                                    :label="$t(`password`)"
                                    label-for="input-2">
                                    <input
                                        autocomplete="new-password"
                                        ref="password"
                                        @focus="saveFocus('password')"
                                        @keyup="checkUsernamePassword"
                                        v-model="password"
                                        type="password"
                                        :disabled="
                                            auth_limit || loading || collapsing
                                        "
                                        :placeholder="$t('enter password')"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted && v$.password.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.password
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">{{
                                            $t(item.$message)
                                        }}</span>
                                    </div>
                                </b-form-group>

                                <b-collapse
                                    class="mt-1"
                                    :visible="pin_form"
                                    @hide="this.collapsing = true"
                                    @hidden="setFocus()">
                                    <b-form-group
                                        class="mb-3"
                                        id="input-group-2"
                                        label-for="input-2">
                                        <input
                                            style="display: none"
                                            readonly
                                            id="input-2"
                                            v-model="pin"
                                            type="password"
                                            :placeholder="$t('enter PIN')"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    submitted && v$.pin.$error,
                                            }" />

                                        <div>
                                            <div class="pin-placeholder">
                                                <div>
                                                    {{ pin[0] ? '⦿' : '-' }}
                                                </div>
                                                <div>
                                                    {{ pin[1] ? '⦿' : '-' }}
                                                </div>
                                                <div>
                                                    {{ pin[2] ? '⦿' : '-' }}
                                                </div>
                                                <div>
                                                    {{ pin[3] ? '⦿' : '-' }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-for="(item, index) in v$.pin
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback text-center">
                                            <span v-if="item.$message">{{
                                                $t(item.$message)
                                            }}</span>
                                        </div>

                                        <div class="tel-pad mt-2">
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('1')">
                                                1
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('2')">
                                                2
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('3')">
                                                3
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('4')">
                                                4
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('5')">
                                                5
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('6')">
                                                6
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('7')">
                                                7
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('8')">
                                                8
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('9')">
                                                9
                                            </button>
                                            <button
                                                class="clear"
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="clearPIN()">
                                                {{ $t('clear') }}
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="appendPIN('0')">
                                                0
                                            </button>
                                            <button
                                                type="button"
                                                :disabled="
                                                    auth_limit || loading
                                                "
                                                @click="backSpacePIN()">
                                                ←
                                            </button>
                                        </div>
                                    </b-form-group>

                                    <!-- hehehe -->
                                </b-collapse>

                                <b-form-checkbox
                                    class="form-check"
                                    size="sm"
                                    id="customControlInline"
                                    name="checkbox-1"
                                    value="accepted"
                                    :disabled="auth_limit || loading"
                                    unchecked-value="not_accepted">
                                    {{ $t('save password') }}
                                </b-form-checkbox>

                                <div class="mt-3 d-grid">
                                    <b-button
                                        v-if="!loading"
                                        :disabled="auth_limit || loading"
                                        type="submit"
                                        variant="dark"
                                        class="btn-block"
                                        >{{ $t('login') }}</b-button
                                    >
                                    <div
                                        v-else
                                        class="d-flex gap-2 justify-content-center">
                                        <b-spinner
                                            small
                                            type="grow"></b-spinner>
                                        <b-spinner
                                            small
                                            type="grow"></b-spinner>
                                        <b-spinner
                                            small
                                            type="grow"></b-spinner>
                                        <b-spinner
                                            small
                                            type="grow"></b-spinner>
                                        <b-spinner
                                            small
                                            type="grow"></b-spinner>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                        <!-- end card-body -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import appConfig from '@/app.config';
import { required, minLength, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import datetime from '../../../mixins/datetime';
import $cookies from 'vue-cookies';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    page: {
        title: 'Login',
        meta: [
            {
                name: 'description',
                content: appConfig.description,
            },
        ],
    },
    mixins: [datetime],
    data() {
        return {
            languages: [
                {
                    flag: require('@/assets/images/flags/us.jpg'),
                    language: 'en',
                    title: 'English',
                },
                {
                    flag: require('@/assets/images/flags/korea.jpg'),
                    language: 'ko',
                    title: 'Korean',
                },
            ],
            submitted: false,
            loading: false,
            error_message: null,
            username: '',
            password: '',
            pin: '',
            auth_attempts: {
                count: $cookies.get('auth_attempts')
                    ? parseInt($cookies.get('auth_attempts').count)
                    : 0,
                expiration: $cookies.get('auth_attempts')
                    ? $cookies.get('auth_attempts').expiration
                    : null,
            },
            // pin_form: false,
            collapsing: false,
            my_focus: '',
            timer: null,
            polling: null,
        };
    },
    validations() {
        return {
            username: {
                required: helpers.withMessage('required', required),
            },
            password: {
                required: helpers.withMessage('required', required),
                minLength: helpers.withMessage(
                    'The password must be at least 4 characters.',
                    minLength(4)
                ),
            },
            pin: {
                required: helpers.withMessage('required', required),
                minLength: helpers.withMessage(
                    'please complete your pin',
                    minLength(4)
                ),
            },
        };
    },
    computed: {
        ...mapState('authfack', ['status']),
        notification() {
            return this.$store ? this.$store.state.notification : null;
        },
        pin_form() {
            return this.username != '' && this.password != '';
        },
        auth_limit() {
            return this.auth_attempts.count >= 5;
        },
        current_locale_flag() {
            var flag;
            switch (this.$i18n.locale) {
                case 'en':
                    flag = require('@/assets/images/flags/us.jpg');
                    break;
                case 'ko':
                    flag = require('@/assets/images/flags/korea.jpg');
                    break;
            }
            return flag;
        },
    },
    watch: {
        auth_limit() {
            this.reset();
            if (this.auth_limit) {
                this.pollData();
            }
        },
        timer() {
            if (this.timer === '00:00') {
                this.error_message = null;
                clearInterval(this.pollData);
                this.auth_attempts.count = 0;
                this.auth_attempts.expiration = null;
            }
        },
    },
    methods: {
        ...mapActions('auth', {
            authLogin: 'loginUser',
        }),
        async tryToLogIn() {
            this.error_message = null;
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid || this.auth_limit) {
                return;
            } else {
                var fd = new FormData();
                fd.append('username', this.username);
                fd.append('password', this.password);
                fd.append('pin', this.pin);

                this.loading = true;
                const res = await this.authLogin(fd);

                if (res.success) {
                    this.$router.replace({ path: '/' }).catch(() => {});
                } else {
                    this.loading = false;
                    this.error_message = res.message;
                    const expirationDate = new Date();
                    expirationDate.setTime(
                        expirationDate.getTime() + 3 * 60 * 1000
                    );
                    this.auth_attempts.count += 1;
                    this.auth_attempts.expiration = expirationDate;

                    $cookies.set(
                        'auth_attempts',
                        {
                            count: this.auth_attempts.count,
                            expiration: expirationDate,
                        },
                        expirationDate
                    );

                    if (res.message == 'Invalid credentials') {
                        this.reset();
                    }
                    if (res.message == 'Incorrect pin') {
                        this.pin = '';
                    }
                    // Swal.fire({
                    //     background: "#2a3042",
                    //     color: "#fff",
                    //     icon: "error",
                    //     title: "Invalid user credentials",
                    //     showConfirmButton: false,
                    //     // timer: 1500,
                    // });
                }
            }
        },
        appendPIN(c) {
            if (this.pin.length < 4) {
                this.pin += c;
            }
        },
        clearPIN() {
            this.pin = '';
        },
        backSpacePIN() {
            this.pin = this.pin.slice(0, -1);
        },
        reset() {
            this.submitted = false;
            this.my_focus = 'username';
            this.setFocus();
            this.username = '';
            this.password = '';
            this.pin = '';
        },
        checkUsernamePassword() {
            // if (this.username != '' && this.password != '') {
            //     this.pin_form = true;
            // } else {
            //     this.pin_form = false;
            //     this.pin = '';
            // }
        },
        setFocus() {
            this.collapsing = false;
            if (this.my_focus == 'username') {
                // setTimeout(() => {
                this.$nextTick(() => this.$refs.username.focus());
                // }, 500);
            } else if (this.my_focus == 'password') {
                // setTimeout(() => {
                this.$nextTick(() => this.$refs.password.focus());
                // }, 500);
            }
        },
        saveFocus(r) {
            this.my_focus = r;
        },
        pollData() {
            this.polling = setInterval(() => {
                this.timer = this.getTimeDifference(
                    new Date(),
                    new Date(this.auth_attempts.expiration)
                );
            }, 1000);
        },
        changeLocale(lang) {
            // alert(lang);
            this.$i18n.locale = lang;
            $cookies.set('locale', lang);
        },
    },
    mounted() {
        if ($cookies.get('auth_attempts')) {
            this.pollData();
        }
    },
};
</script>
<style scoped>
.form-check {
    display: flex;
    align-items: center;
    gap: 0.5em;
}
</style>
